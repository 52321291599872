@font-face {
  font-family: 'AkkuratLLTT';
  src: local('AkkuratLLTT'), url(./fonts/AkkuratLLTT/AkkuratLLTT-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'AkkuratLLTT';
  src: local('AkkuratLLTT'), url(./fonts/AkkuratLLTT/AkkuratLLTT-Bold.ttf) format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'VictorSerif';
  src: local('VictorSerif'), url(./fonts/VictorSerif/VictorSerif-60Semibold.otf) format('opentype');
  font-weight: 600;
}

@font-face {
  font-family: 'VictorSerifItalic';
  src: local('VictorSerif'), url(./fonts/VictorSerif/VictorSerif-65SemiboldItalic.otf) format('opentype');
  font-weight: 600;
}

.App {
}
